@import '../../../assets/scss/app-variables';

.half-bg {
  background: linear-gradient(290.33deg, #FEDEDD 6.97%, #FFC8C8 56.56%, #FFB29A 96.71%);
  position: fixed;
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.slideContainer {
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;

    img {
      width: 65px;
      height: 13px;
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include screen('small') {
  .slideContainer {
    .logoContainer {
      display: none;
    }
  }
}
