@import '../../../assets/scss/app-variables';

.see-you-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodySeeYou {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: $space-between-x2;

      h2 {
        margin: 0;
      }

      img {
        width: 235px;
        height: 47px;
      }
    }
  }

  .motorcycleContainer {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
    
    img {
      width: 576px;
    }
  }
}

@include screen('large') {
  .see-you-container {
    justify-content: space-between;

    .slideBodySeeYou {
      justify-content: center;
      width: calc(50% - 20px);
    }

    .motorcycleContainer {
      margin-right: -20px;
      width: calc(50% + 20px);
    }
  }
}
  
@include screen('small') {
  .see-you-container {
    height: 100%;
    flex-direction: column;

    .slideBodySeeYou {
      height: 100%;
    }

    .motorcycleContainer img {
      width: 289px;
    }

    .treeContainer {
      display: none;
    }
  }
}