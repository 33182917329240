@import '../../../assets/scss/app-variables';

.bravo-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodyBravo {
    display: flex;
    align-items: center;
    flex-direction: column;

    .content {
      h2 {
        margin-bottom: $space-between;
      }

      p.description {
        color: $secondary-blue;
        margin-bottom: $space-between-x2;
      }
      
      .bravoListContainer {
        display: flex;
        flex-direction: column;
        margin: $space-between-x2 0;
    
        .listContent {
          display: flex;
          align-items: center;
    
          &:first-child {
            margin-bottom: $space-between;
          }
    
          .bulletList {
            background-color: $secondary-blue;
            border-radius: 50%;
            box-shadow: 0px 4px 13px $secondary-blue;
            height: 10px;
            min-width: 10px;
            max-width: 10px;
            margin-right: $space-between;
          }
    
          p {
            color: $primary-blue;
          }
        }
      }

      .disclaimer {
        background-color: #F1F0F7;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: $space-between-x2;
    
        .disclaimerContent {
          display: flex;
          align-items: center;
          padding: 0 6px 0 2px;
    
          img {
            width: 70px;
            height: 75px;
          }
    
          p {
            color: $primary-blue;
            margin-left: 16px;
            text-align: left;
        
            span {
              color: $secondary-blue;
            }
          }
        }
      }

      .noThanksLink {
        margin: $space-between 0;
      }
    }
  }

  .motorcycleContainer {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-right: -20px;
    padding: 0;
    width: calc(50% + 20px);
    
    img {
      width: 576px;
    }
  }
}

@include screen('large') {
  .bravo-container {
    justify-content: space-between;

    .slideBodyBravo {
      width: calc(50% - 20px);

      .content {
        width: 385px;
      }
    }
  }
}
  
@include screen('small') {
  .bravo-container {
    height: calc(100% - 25px);
    flex-direction: column;

    .slideBodyBravo {
      height: 100%;
    }

    .motorcycleContainer {
      display: none;
    }
  }
}