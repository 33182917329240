@import '../../../assets/scss/app-variables';

.btn {
  background-color: $secondary-blue;
  border: none;
  border-radius: 26px;
  box-shadow: 0px 4px 13px $secondary-blue-20;
  color: $white;
  cursor: pointer;
  font-size: 1rem; // 16px
  font-weight: 600;
  line-height: 1.5rem; // 24px
  height: 48px;
  outline: none;
  padding: 12px 48px;

  &:disabled {
    background-color: $shadow;
    box-shadow: none;
    color: $primary-blue;
    pointer-events: none;
  }

  &.flat {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    color: $primary-blue;
  }

  &.tall {
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 4px 13px $shadow;
    color: $primary-blue;
    padding: 0;
    height: 60px;
    width: 266px;

    &:active {
      background-color: $secondary-blue;
      color: $white;
    }

      
    @include screen('small') {
      height: 98px;
      width: 150px;
    }
  }
}