@import '../../../assets/scss/app-variables';

.verify-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodyVerify {
    display: flex;
    align-items: center;
    flex-direction: column;

    .content {
      h2 {
        margin-bottom: $space-between;
      }

      p.description {
        margin-bottom: $space-between-x2;
      }

      h4.userEmail {
        color: $secondary-blue;
      }

      .changeEmailLink {
        margin: 10px 0 $space-between-x2;
        text-align: left;
      }

      .disclaimer {
        background-color: #F1F0F7;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: $space-between;

        &.marginBx2 {
          margin-bottom: $space-between-x2;
        }
    
        .disclaimerContent {
          display: flex;
          align-items: center;
          padding: 0 6px 0 16px;
    
          img {
            width: 63px;
            height: 63px;
          }
    
          p {
            color: $primary-blue;
            text-align: left;
          }
        }
      }
    }

    .verifyError {
      color: $error-color;
      margin-bottom: $space-between;
    }

    .reSendLink {
      margin: $space-between 0;
    }
  }

  .treeContainer {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-right: -20px;
    padding: 0;
    width: calc(50% + 20px);
    
    img {
      width: 576px;
    }
  }
}

@include screen('large') {
  .verify-container {
    justify-content: space-between;

    .slideBodyVerify {
      width: calc(50% - 20px);

      .content {
        width: 385px;
      }
    }
  }
}
  
@include screen('small') {
  .verify-container {
    height: calc(100% - 25px);
    flex-direction: column;

    .slideBodyVerify {
      height: 100%;
    }

    .treeContainer {
      display: none;
    }
  }
}